import React from "react"
import { Link } from "gatsby"
import SearchBox from "../layout/searchBox"
import MANUFACTURERS_ICON from "../../images/buy-equipment/SSLLC_Icon_Manufacturers.svg"
import NEW_ARRIVALS_ICON from "../../images/buy-equipment/SSLLC_Icon_New-Arrivals.svg"
import LAB_ICON from "../../images/buy-equipment/SSLLC_Icon_Lab-Equipment-1.svg"
import PROCESSING_ICON from "../../images/buy-equipment/SSLLC_Icon_Processing-Equipment.svg"
import PACKAGING_ICON from "../../images/buy-equipment/SSLLC_Icon_Packaging-Equipment.svg"
import REFURBISHED_ICON from "../../images/buy-equipment/SSLLC_Icon_Refurbished.svg"
import MEDICAL_ICON from "../../images/buy-equipment/SSLLC Icon_Medical Equipment 1.svg"

export default function BrowseByCat(props) {
  return (
    <div className="shadow-custom-out">
      <div className="container max-w-screen-xl w-full py-14">
        <div className="text-center">
          <h2 className="pb-2">{props.title}</h2>
          <span>{props.body}</span>
        </div>
        <div className="md:px-16 lg:px-20 pt-6">
          <SearchBox />
        </div>
        {/* Mobile Layout */}
        <div className="grid grid-cols-2 gap-x-14 gap-y-20 md:hidden mt-10 items-center justify-items-center text-center">
          <div className="flex flex-col">
            <Link to={"/buy-equipment/lab-equipment"}>
              <img
                className="mx-auto"
                src={LAB_ICON}
                alt="Lab Equipment"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Lab Equipment</h4>
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/buy-equipment/processing-equipment"}>
              <img
                className="mx-auto"
                src={PROCESSING_ICON}
                alt="Processing Equipment"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Processing Equipment</h4>
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/buy-equipment/packaging-equipment"}>
              <img
                className="mx-auto"
                src={PACKAGING_ICON}
                alt="Packaging Equipment"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Packaging Equipment</h4>
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/buy-equipment/medical-equipment"}>
              <img
                className="mx-auto"
                src={MEDICAL_ICON}
                alt="Medical Equipment"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Medical Equipment</h4>
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/catalog/refurbished"}>
              <img
                className="mx-auto"
                src={REFURBISHED_ICON}
                alt="Refurbished Equipment"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Refurbished Equipment</h4>
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/new-arrivals"}>
              <img
                className="mx-auto"
                src={NEW_ARRIVALS_ICON}
                alt="New Arrivals"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">New Arrivals</h4>
            </Link>
          </div>
          <div className="flex flex-col col-span-2 justify-self-center">
            <Link to={"/buy-equipment/manufacturers"}>
              <img
                className="mx-auto"
                src={MANUFACTURERS_ICON}
                alt="Manufacturers"
                width={150}
                height={150}
              />
              <h4 className="text-charcoal">Manufacturers</h4>
            </Link>
          </div>
        </div>

        {/* Desktop Layout - 3 & 4 */}
        <div className="hidden md:block mt-10 text-center">
          <div className="grid grid-cols-3 gap-x-14 gap-y-16 items-center justify-items-center">
            <div className="flex flex-col">
              <Link to={"/buy-equipment/lab-equipment"}>
                <img
                  className="mx-auto"
                  src={LAB_ICON}
                  alt="Lab Equipment"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Lab Equipment</h4>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to={"/buy-equipment/processing-equipment"}>
                <img
                  className="mx-auto"
                  src={PROCESSING_ICON}
                  alt="Processing Equipment"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Processing Equipment</h4>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to={"/buy-equipment/packaging-equipment"}>
                <img
                  className="mx-auto"
                  src={PACKAGING_ICON}
                  alt="Packaging Equipment"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Packaging Equipment</h4>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-x-14 gap-y-16 mt-10 items-center justify-items-center">
            <div className="flex flex-col">
              <Link to={"/buy-equipment/medical-equipment"}>
                <img
                  className="mx-auto"
                  src={MEDICAL_ICON}
                  alt="Medical Equipment"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Medical Equipment</h4>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to={"/catalog/refurbished"}>
                <img
                  className="mx-auto"
                  src={REFURBISHED_ICON}
                  alt="Refurbished Equipment"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Refurbished Equipment</h4>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to={"/new-arrivals"}>
                <img
                  className="mx-auto"
                  src={NEW_ARRIVALS_ICON}
                  alt="New Arrivals"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">New Arrivals</h4>
              </Link>
            </div>
            <div className="flex flex-col">
              <Link to={"/buy-equipment/manufacturers"}>
                <img
                  className="mx-auto"
                  src={MANUFACTURERS_ICON}
                  alt="Manufacturers"
                  width={150}
                  height={150}
                />
                <h4 className="text-charcoal">Manufacturers</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
