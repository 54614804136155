import React, { useState } from 'react';

export default function Accordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 for the first item

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="bg-light-gray w-full px-8 pt-8 h-full rounded-md">
      {items.map((item, index) => (
        <div key={index} className="pb-6 mb-4 items-center">
          {/* Accordion Header */}
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex items-center gap-5">
              <span
                className={`text-3xl font-bold items-center ${
                  activeIndex === index ? 'text-blue' : 'text-red'
                }`}
              >
                {activeIndex === index ? '–' : '+'}
              </span>
              <h3
                className={`text-[1.4rem] ${
                  activeIndex === index
                    ? 'mb-0'
                    : 'mb-0'
                }`}
              >
                {item.title}
              </h3>
            </div>
          </div>

          {/* Accordion Content with Smooth Transition */}
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeIndex === index ? 'max-h-[200px]' : 'max-h-0'
            }`}
          >
            <div className="mt-3 ">
              <p className='font-normal'>{item.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}